import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TabsTwo extends Component{
    render(){
        let 
        tab1 = "Stimmprofil",
        tab2 = "Technische Austattung",
        
        tab4 = "Ausbildung und Erfahrung";

        const { tabStyle } = this.props
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        
                                        <Tab>{tab4}</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <a href="/service">Stimmalter</a>
                                                    25-45
                                                </li>
                                                <li>
                                                    <a href="/service">Klangfarbe</a>
                                                    Bariton/Bass - Tief, Klar
                                                </li>
                                                <li>
                                                    <a href="/service">Sprachen & Dialekte</a>
                                                    Deutsch (Österreich),
                                                    Dialekte: Wienerisch, Kärntnerisch
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="/service">Mikrofon<span> - Bluebird Yeti</span></a>
                                               </li>
                                               <li>
                                                   <a href="/service">Remote -<span> SessionLinkPRO</span></a> 
                                               </li>
                                               
                                           </ul>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="/service">Schule des Sprechens<span> - in Ausbildung</span></a> 2022 - 2023
                                               </li>
                                               <li>
                                                   <a href="/service">Bachelor of Science<span> - Medientechnik</span></a> Audio, Video, 2D and 3D Motion Design
                                               </li>
                                               
                                           </ul>
                                       </div>
                                    </TabPanel>



                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="/service">Sr. Front-end Engineer<span> - Google</span></a> 2018 - Current
                                               </li>
                                               <li>
                                                   <a href="/service">Front-end Engineer<span> - Microsoft</span></a> 2017 - 2018
                                               </li>
                                               <li>
                                                   <a href="/service">Software Engineer<span> - Alibaba </span></a> 2013- 2014
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="/service">BSc In CSE<span> - ABC University, Los Angeles, CA</span></a> 2010
                                               </li>
                                               <li>
                                                   <a href="/service">Diploma in Computer Science<span> - Gamma Technical Institute</span></a> 2009
                                               </li>
                                               <li>
                                                   <a href="/service">Graphic Designer<span> - ABC Institute, Los Angeles, CA</span></a> 2007
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;