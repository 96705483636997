import React, { Component, useState } from "react";
import { FiCheck } from "react-icons/fi";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

 function Soundcloud({props})
 {
    const [currentTrack, setTrackIndex] = useState(0);

    const handleClickNext = () => {
        console.log('click next')
          setTrackIndex((currentTrack) =>
              currentTrack < Playlist.length - 1 ? currentTrack + 1 : 0
          );
      };
    
    const handleEnd = () => {
      console.log('end')
      setTrackIndex((currentTrack) =>
              currentTrack < Playlist.length - 1 ? currentTrack + 1 : 0
          );
    }


const Playlist = [
  { id: 0, title: "1. Werbung - Cool, Seriös, Emotional", src: "https://allesistsokompliziert.s3.eu-west-3.amazonaws.com/Audi+Quattro+mp3.mp3" },
  { id: 1, title: "2. Social Media Werbung - Seriös, Jung, Klar", src: "https://allesistsokompliziert.s3.eu-west-3.amazonaws.com/SocialMediaCommercial.wav" },
  { id: 2, title: "3. Werbung - Heiter, Klar, Neutral", src: "https://allesistsokompliziert.s3.eu-west-3.amazonaws.com/Reicheis.wav" },
  { id: 3, title: "4. Telefonansage - Klar, Neutral", src: "https://allesistsokompliziert.s3.eu-west-3.amazonaws.com/Telefonwav.wav" },
  { id: 4, title: "5. Erklärvideo - Neutral, Klar", src: "https://allesistsokompliziert.s3.eu-west-3.amazonaws.com/DemoErkl%C3%A4rvideo.mp3" },

  
  
];

        return(
            <>
            
                
                <div class="list-group" id="list-tab" role="tablist" hover="active">
                
                
                  
                  {Playlist.map(song => {
                    const className = song.id % 2 === 0 ? 'list-group-item list-group-item-action list-group-item-secondary' : 'list-group-item list-group-item-action list-home-dark';
                    return (<a key={song.id}  className={className} id="list-home-list" data-toggle="list" onClick={() => setTrackIndex(song.id)} role="button" aria-controls="home">{song.title}</a>)
                  })}
                  </div>
                  
                <AudioPlayer
                volume="0.5"
                src={Playlist[currentTrack].src}
                showSkipControls
                onClickNext={handleClickNext}
                onEnded={handleEnd}
                // Try other props!
                />
                <p>
                </p>
            </>
        )
}
export default Soundcloud;



