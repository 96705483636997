import React, { useState } from "react";

const Playlist = [
  { id: 1, title: "song 1", src: "https://hanzluo.s3-us-west-1.amazonaws.com/music/ziyounvshen.mp3" },
  { id: 2, title: "song 2", src: "https://hanzluo.s3-us-west-1.amazonaws.com/music/wuyuwuqing.mp3" },
  { id: 3, title: "song 3", src: "https://hanzluo.s3-us-west-1.amazonaws.com/music/suipian.mp3" },
];

function Player() {
  const [currentSong, setCurrentSong] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audio, setAudio] = useState(new Audio());

  const playSong = (song) => {
    setCurrentSong(song);
    setIsPlaying(true);
    audio.src = song.src;
    audio.play();
  };

  const pauseSong = () => {
    setIsPlaying(false);
    audio.pause();
  };

  const stopSong = () => {
    setIsPlaying(false);
    setCurrentSong(null);
    audio.pause();
    audio.currentTime = 0;
  };

  const nextSong = () => {
    const currentIndex = Playlist.indexOf(currentSong);
    if (currentIndex === Playlist.length - 1) {
      setCurrentSong(Playlist[0]);
    } else {
      setCurrentSong(Playlist[currentIndex + 1]);
    }
    audio.src = currentSong.src;
    audio.play();
  };

  const prevSong = () => {
    const currentIndex = Playlist.indexOf(currentSong);
    if (currentIndex === 0) {
      setCurrentSong(Playlist[Playlist.length - 1]);
    } else {
      setCurrentSong(Playlist[currentIndex - 1]);
    }
    audio.src = currentSong.src;
    audio.play();
  };

  return (
    <div className="player-container" style={{}}>

    <div class="row">
      <div class="col-4">
      <div class="list-group" id="list-tab" role="tablist">
      {Playlist.map(song => 
        song.id == currentSong?.id
        ?<a className="list-group-item list-group-item-action active" id="list-home-list" data-toggle="list" onClick={() => playSong(song)} role="tab" aria-controls="home">{song.title}</a>
        :<a className="list-group-item list-group-item-action" id="list-home-list" data-toggle="list" onClick={() => playSong(song)} role="tab" aria-controls="home">{song.title}</a>
       )}
       
  </div>
      </div>
      <div class="col-8">
        <div class="tab-content" id="nav-tabContent">
          <div class="tab-pane fade show active" id="list-home" role="tabpanel" aria-labelledby="list-home-list">...</div>
          <div class="tab-pane fade" id="list-profile" role="tabpanel" aria-labelledby="list-profile-list">...</div>
          <div class="tab-pane fade" id="list-messages" role="tabpanel" aria-labelledby="list-messages-list">...</div>
          <div class="tab-pane fade" id="list-settings" role="tabpanel" aria-labelledby="list-settings-list">...</div>
        </div>
      </div>
    </div>

 
    </div>
  );
}

export default Player;
